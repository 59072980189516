import React from "react";
import { Modal, ModalOverlay, ModalContent, ModalBody, useClipboard, InputRightAddon, InputLeftAddon, useColorModeValue } from "@chakra-ui/react";
import { Text, VStack, InputGroup, Input, Button } from "@chakra-ui/react";
import { FaCheck, FaCopy, FaLink } from "react-icons/fa";

const LinkModal = ({ shareLink, showLink, setShowLink }) => {
  const { hasCopied, onCopy } = useClipboard(shareLink);
  const onClose = () => {
    setShowLink(false);
  };

  const inputBgColor = useColorModeValue("white", "gray.700");
  const inputTextColor = useColorModeValue("black", "white");
  const inputBorderColor = useColorModeValue("gray.300", "gray.600");

  return (
    <Modal size="2xl" isOpen={showLink} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <VStack spacing={4}>
            <InputGroup size="md">
              <InputLeftAddon>
                <FaLink />
              </InputLeftAddon>
              <Input
                fontSize="14px"
                value={shareLink}
                placeholder="Generated link will appear here"
                isReadOnly
                bg={inputBgColor} 
                color={inputTextColor} 
                borderColor={inputBorderColor} 
                _focus={{
                  borderColor: inputBorderColor,
                }}
              />
              <InputRightAddon width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={onCopy} disabled={!shareLink}>
                  {hasCopied ? <FaCheck /> : <FaCopy />}
                </Button>
              </InputRightAddon>
            </InputGroup>
            {shareLink && (
              <Text color="red.500" width={"100%"} fontSize="sm">
                The link is valid only for 30 mins.
              </Text>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LinkModal;
