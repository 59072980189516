import {
  Box,
  Text,
  Input,
  Button,
  Flex,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react'

import React from "react";
import { useColorModeValue } from "@chakra-ui/react";
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'
import { Spinner } from "@chakra-ui/react";
import { user, change_password } from "../../../Service";
import { useState, useEffect } from "react";
import { CiCircleCheck } from "react-icons/ci";
import axios from "axios";
import { BASE_URL } from "../../../Service";
import toast from 'react-hot-toast';
import { useUserContext } from "../../../contexts/UserContext";

export default function Account() {
  const bgColor = useColorModeValue("#fff", "navy.800");
  const bg2Color = useColorModeValue("#F4F7FD", "navy.900");
  const textColor = useColorModeValue("navy.700", "white");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [theUser, setUser] = useState();
  const [password, setPassword] = useState('')
  const { founder, free, n_bots } = useUserContext();


  const getPortalSession = async (event) => {
    event.preventDefault();
    setLoading2(true);
    const auth_token = window.localStorage.getItem("user-token");
    const authToken = 'Token ' + auth_token;

    try {
      const response = await axios.get(
        `${BASE_URL}/customer-portal`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': authToken
          }
        }
      );

      setLoading2(false);
      window.location.href = response.data.session;
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      }
      setLoading2(false);
    }
  };

  const updatePassword = () => {
    setLoading3(true);
    const token = window.localStorage.getItem('user-token');

    if (password.length < 8) {
      setLoading3(false);
      toast.error('Password must be at least 8 characters long');
      return;
    }

    change_password(token, password).then((res) => {
      setLoading3(false);
      toast.success('Password updated successfully');
    }).catch((error) => {
      setLoading3(false);
      if (error.response) {
        toast.error(error.response.data.message);
      }
    });
  }

  const getUser = () => {
    const token = window.localStorage.getItem('user-token');
    setLoading(true);
    user(token).then((res) => {
      setLoading(false);
      setUser(res.data);
    }).catch((error) => {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.message);
      }
    });
  }

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
// 
  return (
    <>
      {loading ? <Flex
        mt='100px'
        mb='20px'
        ml={6}
        justifyContent='start'
        direction={{ base: "column", md: "row" }}
        align={{ base: "start", md: "center" }}>
        <Spinner color="blue.500" size="md" mr={3} />
        <Text color={textColor} fontSize='lg' ms='4px' fontWeight='normal'>
          Please wait...
        </Text>
      </Flex> : <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
        <Box mt='45px'
          mb='20px'>
          <Text color={textColor} fontSize='2xl' fontWeight='bolder'>
            Account Settings {founder ? '(Founder)' : ''}
          </Text>
        </Box>
        <Box display='flex' gap='20px'>
          <Box bg={bgColor} width='calc(100% - 270px)' p='40px'>
            <TableContainer>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td width='100px'>
                      <Text color={textColor} fontSize='md' fontWeight='bolder'>
                        Email
                      </Text>
                    </Td>
                    <Td>
                      <Text color={textColor} fontSize='md' fontWeight='normal'>
                        {theUser?.email}
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text color={textColor} fontSize='md' fontWeight='bolder'>
                        Password
                      </Text>
                    </Td> 
                    <Td>
                      <Input _focus={{ boxShadow: "none" }}
                        bg={bg2Color}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        color={textColor}
                        style={{ borderRadius: "50px", height: '50px', width: '50%' }} type='password' placeholder='New Password' />
                      <Button
                        fontSize='sm'
                        fontWeight='500'
                        px={10}
                        height='50px'
                        ml={2}
                        variant='brand'
                        onClick={() => {
                          updatePassword();
                        }}
                      >
                        {
                          loading3 ? <Spinner color="white" size="md" /> : 'Update'
                        }
                      </Button>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
            <Box>
              {founder || !free ? null : <Button
                fontSize='sm'
                fontWeight='500'
                height='50px'
                mt={4}
                ml={4}
                variant='brand'
                onClick={(e) => {
                  getPortalSession(e);
                }}
              >
                {
                  loading2 ? <Spinner color="white" size="md" /> : 'Manage Subscription'
                }
              </Button>}
              {theUser?.consumed_tokens < theUser?.total_tokens * 0.1 && (
                <Button
                  fontSize='sm'
                  fontWeight='500'
                  height='50px'
                  mt={4}
                  ml={4}
                  variant='brand'
                  onClick={() => {
                    // Add your action here when the button is clicked
                  }}
                >
                  Buy Credits
                </Button>
              )}
            </Box>
            {/* <Text fontSize='sm' mt={10} color='gray.500' ml={4}>
              Total storage used: {theUser?.total_storage} GB
            </Text> */}
            <Text fontSize='sm' mt={2} color='gray.500' ml={4}>
              <span style={{ color: "red" }}>* </span>You can create {n_bots === 999 ? 'unlimited' : n_bots} bots.
            </Text>
          </Box>
          <Box bg={bgColor} width='270px' p='40px' style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <CircularProgress value={
              (theUser?.total_tokens - theUser?.consumed_tokens) / theUser?.total_tokens * 100
            } color="green.300" size='250' thickness='5'>
              <CircularProgressLabel fontSize='md' fontWeight='bold'>{theUser?.consumed_tokens.toLocaleString()} / {theUser?.total_tokens.toLocaleString()}</CircularProgressLabel>
            </CircularProgress>
            <Text fontSize='sm' color='gray.500' mt='10px'>Your credits usage</Text>
          </Box>
        </Box>
        <Box mt='20px'
          mb='20px'>
          <Text color={textColor} fontSize='2xl' fontWeight='bolder'>
            Activity Log
          </Text>
        </Box>
        <Box bg={bgColor} width='100%' mt={5} p='20px'>
          <List spacing={3}>
            {theUser?.activities.map((activity, index) => (
              <ListItem key={index}>
                <ListIcon as={CiCircleCheck} color='green.500' />
                {activity}
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>}
    </>
  );
}
