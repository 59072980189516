import React, { useEffect, useState } from 'react';
import { useBotContext } from "../../../../contexts/BotContext";
import "../../../../assets/css/Analytics.css"; // Import the CSS file

const Analytics = () => {
  const [conversationCount, setConversationCount] = useState(null);
  const [error, setError] = useState(null); 
  const userId = 'afacafq3f1sf3'; 
  const { botId } = useBotContext(); 
  
  useEffect(() => {
    const fetchConversationCount = async () => {
      try {
        const token = localStorage.getItem('user-token');
        
        if (!token) {
          throw new Error('User token not found in localStorage');
        }
    
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/bot/converse_count/${botId}/${userId}/`, {
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`,
          },
        });
    
        if (response.ok) {
          const data = await response.json(); 
          setConversationCount(data.conversation_count);
        } else {
          const text = await response.text();
          console.error('Error response:', text);
          throw new Error(`Request failed with status ${response.status}: ${text}`);
        }
      } catch (error) {
        console.error('Error fetching conversation count:', error);
        setError(error.message); 
      }
    };    
  
    fetchConversationCount();
  }, [botId]);

  return (
    <div style={{ display: 'flex', gap: '20px', padding: '20px' }}>
      <div className="card">
        <div style={overlayStyle}></div>
        <div style={contentStyle}>
          <h2>Bot ID</h2>
          <p>{botId}</p>
        </div>
      </div>
      <div className="card">
        <div style={overlayStyle}></div>
        <div style={contentStyle}>
          <h2>Conversation Count (Last 30 Days)</h2>
          {error ? (
            <p style={{ color: 'red' }}>Error: {error}</p>
          ) : (
            <p>{conversationCount !== null ? conversationCount : 'Loading...'}</p>
          )}
        </div>
      </div>
    </div>
  );
};

const overlayStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.3)',
  zIndex: 1,
};

const contentStyle = {
  position: 'relative',
  zIndex: 2,
  color: '#333',
};

export default Analytics;
