// Chakra imports
import { Portal, Box, useDisclosure } from '@chakra-ui/react';
import Footer from '../../components/footer/FooterAdmin.js';
// Layout components
import Navbar from '../../components/navbar/NavbarAdmin.js';
import Sidebar from '../../components/sidebar/Sidebar.js';
import { SidebarContext } from '../../contexts/SidebarContext';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import routes from '../../routes';
import { Toaster } from 'react-hot-toast';

// Custom Chakra theme
export default function Dashboard(props) {
    const { ...rest } = props;
    // states and functions
    const [fixed] = useState(false);
    const [route, setRoute] = useState("Dashboard");
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const location = useLocation();

    // functions for changing the states from components
    const getRoute = () => {
        return window.location.pathname !== '/admin/full-screen-maps';
    };

    const getActiveNavbar = (routes) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveNavbar = getActiveNavbar(routes[i].items);
                if (collapseActiveNavbar !== activeNavbar) {
                    return collapseActiveNavbar;
                }
            } else if (routes[i].category) {
                let categoryActiveNavbar = getActiveNavbar(routes[i].items);
                if (categoryActiveNavbar !== activeNavbar) {
                    return categoryActiveNavbar;
                }
            } else {
                if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                    return routes[i].secondary;
                }
            }
        }
        return activeNavbar;
    };

    const getActiveNavbarText = (routes) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
                if (collapseActiveNavbar !== activeNavbar) {
                    return collapseActiveNavbar;
                }
            } else if (routes[i].category) {
                let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
                if (categoryActiveNavbar !== activeNavbar) {
                    return categoryActiveNavbar;
                }
            } else {
                if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                    return routes[i].messageNavbar;
                }
            }
        }
        return activeNavbar;
    };

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === '/app') {
                return <Route name={prop.name} path={prop.layout + prop.path} component={prop.component} key={key} />;
            }
            if (prop.collapse) {
                return getRoutes(prop.items);
            }
            if (prop.category) {
                return getRoutes(prop.items);
            } else {
                return null;
            }
        });
    };

    const findRouteName = (routes, path) => {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].layout + routes[i].path === path) {
                return routes[i].name;
            }
            if (routes[i].collapse || routes[i].category) {
                let name = findRouteName(routes[i].items, path);
                if (name) {
                    return name;
                }
            }
        }
        return "Dashboard"; // Default name
    };

    // Update route name when location changes
    useEffect(() => {
        setRoute(findRouteName(routes, location.pathname));
    }, [location]);

    document.documentElement.dir = 'ltr';
    const { onOpen } = useDisclosure();
    document.documentElement.dir = 'ltr';

    return (
        <Box>
            <Toaster position="top-right" reverseOrder={false} />
            <Box>
                <SidebarContext.Provider
                    value={{
                        toggleSidebar,
                        setToggleSidebar
                    }}>
                    <Sidebar routes={routes} display='none' {...rest} />
                    <Box
                        float='right'
                        minHeight='100vh'
                        height='100%'
                        position='relative'
                        maxHeight='100%'
                        w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
                        maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
                        transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
                        transitionDuration='.2s, .2s, .35s'
                        transitionProperty='top, bottom, width'
                        transitionTimingFunction='linear, linear, ease'>
                        <Portal>
                            <Box>
                                <Navbar
                                    onOpen={onOpen}
                                    logoText={'Horizon UI Dashboard PRO'}
                                    brandText={route}
                                    secondary={getActiveNavbar(routes)}
                                    message={getActiveNavbarText(routes)}
                                    fixed={fixed}
                                    {...rest}
                                />
                            </Box>
                        </Portal>

                        {getRoute() ? (
                            <Box mx='auto' p={{ base: '20px', md: '30px' }} pe='20px' minH='100vh' pt='50px'>
                                <Switch>
                                    {getRoutes(routes)}
                                    <Redirect from='/' to='/app/dashboard' />
                                </Switch>
                            </Box>
                        ) : null}
                        <Box>
                            <Footer />
                        </Box>
                    </Box>
                </SidebarContext.Provider>
            </Box>
        </Box>
    );
}
